<template>
  <div>
    <v-row class="d-flex">
      <v-col cols="3">
        <date-filter periodType="past-only" range="28" store="c2c" />
      </v-col>
      <v-col cols="6">
        <v-file-input
          accept=".xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          class="ml-3 mr-3"
          outlined
          :label="filename"
          v-model="file"
        />
      </v-col>
      <v-col cols="3">
        <v-btn
          color="primary"
          class="me-3 mt-3"
          @click.native="generateExport"
          :disabled="loading"
        >
          Retrouver les infos GA
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import useAxios from "@/hooks/useAxios";

import DateFilter from "@/components/common/filters/DateFilter";

export default {
  name: "Filters",
  components: {
    DateFilter,
  },
  data() {
    return {
      loading: false,
      file: undefined,
      filename: "liste_urls.xlsx",
    };
  },
  setup() {
    const { axiosPost } = useAxios();

    return {
      axiosPost,
    };
  },
  methods: {
    async generateExport() {
      if (!this.file) {
        alert("Please select a file first!");

        return;
      }
      if (this.dates.length !== 2) {
        alert("Please select a range date first!");

        return;
      }

      const [startDate, endDate] = this.dates;
      const formData = new FormData();
      formData.append("start_date", startDate);
      formData.append("end_date", endDate);
      formData.append("file", this.file, this.file.name);

      this.loading = true;
      const { data } = await this.axiosPost("/c2c/ga-url-list/", formData, {
        responseType: "blob",
      });

      const downloadLink = document.createElement("a");
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = this.filename;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);

      this.loading = false;
    },
  },
  computed: {
    dates() {
      return this.$store.getters["c2c/getDates"];
    },
  },
};
</script>

<style></style>
